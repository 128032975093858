<template>
	<ion-page>
		<ion-content :fullscreen="true">
			<div class="header">
				<ion-buttons slot="end">
					<ion-menu-button color="dark"></ion-menu-button>
				</ion-buttons>
				<a href="/app/" router-direction="root" nav-transition="none"><img src="/assets/images/main-logo.png"></a>
			</div>

			<div id="container">
				<div class="home-slider">
					<vueper-slides :dragging-distance="50" :slide-ratio="3 / 4" :infinite=true :arrows=false ref="homeSlider">
						<vueper-slide v-for="(slide, i) in entriesList.home_slider" :key="i" :title="slide.heading" :content="slide.title" :image="slide.image_url" :link="slide.item_url" />
					</vueper-slides>
				</div>
				<div style="text-align: center; margin: 0 -23px">
					<ins class="adsbygoogle" style="display:inline-block;width:320px;height:50px" data-ad-client="ca-pub-0837986485147112" data-ad-slot="7172764154"></ins>
				</div>
				<ul class="articles-grid clearfix">
					<li class="articles-grid--item" 
						v-for="entry in newsHomeGrid"	
						:key="entry.id"
					>
						<a :href=entry.item_url>
							<h3 v-html="entry.title"></h3>
							<img :src=entry.thumb_url :alt=entry.title>
						</a>
					</li>
				</ul>
				<div style="text-align: center; margin: 0 -23px">
					<ins class="adsbygoogle" style="display:inline-block;width:300px;height:250px" data-ad-client="ca-pub-0837986485147112" data-ad-slot="1920437471"></ins>
				</div>
				<ul class="articles-list clearfix">
					<li class="articles-list--item" 
						v-for="entry in newsHomeList"
						:key="entry.id"
					>
						<a :href=entry.item_url>
						<img :src=entry.thumb_url :alt=entry.title>
						<h3 v-html="entry.title"></h3>
						<p v-html="entry.subtitle"></p>
						</a>
					</li>
				</ul>
				<div style="text-align: center; margin: 0 -23px">
					<ins class="adsbygoogle" style="display:inline-block;width:300px;height:250px" data-ad-client="ca-pub-0837986485147112" data-ad-slot="8937611273"></ins>
				</div>
				<a class="get-comments-button dark-button" ref="showMoreNews" @click="showMoreNews">Load More News</a>
				<ul class="articles-list more-news-list clearfix" ref="moreNewsList">
					<li class="articles-list--item" 
						v-for="entry in moreNewsList"
						:key="entry.id"
					>
						<a :href=entry.item_url>
						<img :src=entry.thumb_url :alt=entry.title>
						<h3 v-html="entry.title"></h3>
						<p v-html="entry.subtitle"></p>
						</a>
					</li>
				</ul>
				<!--
				<ul class="categories-list categories-container" >
					<li 
						v-for="(entry, key) in entriesList.home_categories"
						:key="entry.id"
						class="categories-list--item category-box clearfix">
						<a :href="`/category/${key}`">
							<img
								v-for="photo in entry.slice(0, 7)" 
								:key="photo.id"
								:src=photo
								:alt=key>
						<h3 v-html="key"></h3>
						</a>
					</li>
				</ul>
				-->
				<div id="footer">
					<p>&copy;<span ref="copyYear"></span> uPolitics<br>
					Breaking Political News, Interviews &amp; Videos</p>
					<img src="/assets/images/main-logo.png" class="bottom-logo">
				</div>
			</div>
		</ion-content>
	</ion-page>
</template>

<script lang="ts">
import { IonButtons, IonContent, IonMenuButton, IonPage } from '@ionic/vue';
import axios from "axios";
import { jsonDataURL, loadAds } from '@uinterview/upolitics'
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import { AppRate } from '@ionic-native/app-rate';
import { SafariViewController } from '@ionic-native/safari-view-controller'
import { InAppBrowser } from '@ionic-native/in-app-browser'

declare let window: any;

export default {
	name: 'Folder',
	components: {
		IonButtons,
		IonContent,
		IonMenuButton,
		IonPage,
		VueperSlides,
		VueperSlide,
	},
	data() {
		return {
			componentKey: 0,
			entriesList: [],
			jsonResp: [],
			newsHomeList: [],
			moreNewsList: [],
			newsHomeGrid: [],
		};
	},
	mounted() {
		loadAds()
		if (localStorage.timesOpened) {
			const newTimesOpened = parseInt(localStorage.timesOpened)+1
			localStorage.setItem('timesOpened', newTimesOpened.toString());
			if( (newTimesOpened==3) || (newTimesOpened==7) || (newTimesOpened%24==0) ) {
				/*
				const preferences = AppRate.getPreferences();
				preferences.simpleMode = true;
				preferences.customLocale = {
					title: "Rate the %@ app!",
					message: "It won't take more than a minute, and you'll help us reach more people.",
					cancelButtonLabel: "No, Thanks",
					laterButtonLabel: "Remind Me Later",
					rateButtonLabel: "Rate It Now",
					yesButtonLabel: "Yes!",
					noButtonLabel: "Not really",
					appRatePromptTitle: "Rate the %@ app!",
					feedbackPromptTitle: 'Give us some feedback?',
				}
				preferences.storeAppURL = {
					ios: 'id1553444988',
					android: 'market://details?id=com.upolitics.app',
					// windows: 'ms-windows-store://review/?ProductId=XXXXXXXX',
				};
				AppRate.setPreferences(preferences);
				console.log(preferences)
				AppRate.promptForRating(true);
				*/
			}
		} else {
			localStorage.setItem('timesOpened', '1');
		}
		const d = new Date
		this.$refs.copyYear.innerHTML = d.getFullYear()
		if (localStorage.appData) {
			const dateNow = Date.now()
			const jsonExpire = (JSON.parse(localStorage.appData).expires)*1000
			if(dateNow <= jsonExpire) {
				this.entriesList = JSON.parse(localStorage.appData)
				this.newsHomeGrid = Object.values(this.entriesList.news).slice(0,6)
				this.newsHomeList = Object.values(this.entriesList.news).slice(6, 16)
				this.moreNewsList = Object.values(this.entriesList.news).slice(16)
				setTimeout(() => this.$refs.homeSlider.init(), 100);
			} else {
				axios.get(jsonDataURL)
					.then(response => {
						this.entriesList = response.data
						localStorage.setItem('appData', JSON.stringify(response.data));
						setTimeout(() => this.$refs.homeSlider.init(), 100);
						this.newsHomeGrid = Object.values(this.entriesList.news).slice(0,6)
						this.newsHomeList = Object.values(this.entriesList.news).slice(6, 16)
						this.moreNewsList = Object.values(this.entriesList.news).slice(16)
					})
			}
		} else {
			axios.get(jsonDataURL)
				.then(response => {
					this.entriesList = response.data
					localStorage.setItem('appData', JSON.stringify(response.data));
					setTimeout(() => this.$refs.homeSlider.init(), 100);
					this.newsHomeGrid = Object.values(this.entriesList.news).slice(0,6)
					this.newsHomeList = Object.values(this.entriesList.news).slice(6, 16)
					this.moreNewsList = Object.values(this.entriesList.news).slice(16)
					setTimeout(() => this.$refs.homeSlider.init(), 100);
				})
		}
	},
	methods:{
		showMoreNews(){
			this.$refs.showMoreNews.style.display = 'none'
			this.$refs.moreNewsList.style.display = 'block'
		},
		
		
		
	
	},
	watch: {
		'$route' (to) {
			if(to.path == '/') {
				if (localStorage.appData) {
					this.entriesList = JSON.parse(localStorage.appData)
				} else {
					axios.get(jsonDataURL)
						.then(response => {
							this.entriesList = response.data
							localStorage.setItem('appData', JSON.stringify(response.data));
							setTimeout(() => this.$refs.homeSlider.init(), 100);
						})
				}
			} else if(to.path.includes('/category/')){
				if (localStorage.appData) {
					this.entriesList = JSON.parse(localStorage.appData)
					this.categCarousel = Object.values(this.entriesList[this.$route.params.id]).slice(0,3)
					this.otherNews = Object.values(this.entriesList[this.$route.params.id]).slice(1,5)
					this.newsList = Object.values(this.entriesList[this.$route.params.id]).slice(6)
				} else {
					axios.get(jsonDataURL)
						.then(response => {
							this.entriesList = response.data
							localStorage.setItem('appData', JSON.stringify(response.data));
							this.categCarousel = Object.values(this.entriesList[this.$route.params.id]).slice(0,3)
							this.otherNews = Object.values(this.entriesList[this.$route.params.id]).slice(1,5)
							this.newsList = Object.values(this.entriesList[this.$route.params.id]).slice(6)
						})
				}
			} else if(to.path.includes('/page/')){
				// do nothing
			} else if(to.path.includes('/videos/')){
				if (localStorage.appData) {
					this.entriesList = JSON.parse(localStorage.appData)
					this.article = this.entriesList.videos[this.$route.params.id]
					this.otherNews = Object.values(this.entriesList.videos).slice(0,6)
					this.otherNews2 = Object.values(this.entriesList.videos).slice(6,18)
				} else {
					axios.get(jsonDataURL)
						.then(response => {
							this.entriesList = response.data
							localStorage.setItem('appData', JSON.stringify(response.data));
							this.article = this.entriesList.videos[this.$route.params.id]
							this.otherNews = Object.values(this.entriesList.videos).slice(0,6)
							this.otherNews2 = Object.values(this.entriesList.videos).slice(6,18)
								})
				}
			} else if(to.path.includes('/polls/')){
				if (localStorage.appData) {
					this.entriesList = JSON.parse(localStorage.appData)
					this.article = this.entriesList.polls[this.$route.params.id]
					this.otherNews = Object.values(this.entriesList.polls).slice(0,6)
					this.otherNews2 = Object.values(this.entriesList.polls).slice(6,18)
				} else {
					axios.get(jsonDataURL)
						.then(response => {
							this.entriesList = response.data
							localStorage.setItem('appData', JSON.stringify(response.data));
							this.article = this.entriesList.polls[this.$route.params.id]
							this.otherNews = Object.values(this.entriesList.polls).slice(0,6)
							this.otherNews2 = Object.values(this.entriesList.polls).slice(6,18)
								})
				}
			} else if(to.path.includes('/polls/')){
				if (localStorage.appData) {
					this.entriesList = JSON.parse(localStorage.appData)
					this.article = this.entriesList.polls[this.$route.params.id]
					this.otherNews = Object.values(this.entriesList.polls).slice(0,6)
					this.otherNews2 = Object.values(this.entriesList.polls).slice(6,18)
				} else {
					axios.get(jsonDataURL)
						.then(response => {
							this.entriesList = response.data
							localStorage.setItem('appData', JSON.stringify(response.data));
							this.article = this.entriesList.polls[this.$route.params.id]
							this.otherNews = Object.values(this.entriesList.polls).slice(0,6)
							this.otherNews2 = Object.values(this.entriesList.polls).slice(6,18)
						})
				}
			} else if(to.path.includes('/coronavirus/')){
				if (localStorage.appData) {
					this.entriesList = JSON.parse(localStorage.appData)
					this.article = this.entriesList.coronavirus[this.$route.params.id]
					this.otherNews = Object.values(this.entriesList.coronavirus).slice(0,6)
					this.otherNews2 = Object.values(this.entriesList.coronavirus).slice(6,18)
				} else {
					axios.get(jsonDataURL)
						.then(response => {
							this.entriesList = response.data
							localStorage.setItem('appData', JSON.stringify(response.data));
							this.article = this.entriesList.coronavirus[this.$route.params.id]
							this.otherNews = Object.values(this.entriesList.coronavirus).slice(0,6)
							this.otherNews2 = Object.values(this.entriesList.coronavirus).slice(6,18)
						})
				}
			} else if(to.path.includes('/ubio/')){
				if (localStorage.appData) {
					this.entriesList = JSON.parse(localStorage.appData)
					this.article = this.entriesList.ubio[this.$route.params.id]
					this.otherNews = Object.values(this.entriesList.ubio).slice(0,6)
					this.otherNews2 = Object.values(this.entriesList.ubio).slice(6,18)
				} else {
					axios.get(jsonDataURL)
						.then(response => {
							this.entriesList = response.data
							localStorage.setItem('appData', JSON.stringify(response.data));
							this.article = this.entriesList.ubio[this.$route.params.id]
							this.otherNews = Object.values(this.entriesList.ubio).slice(0,6)
							this.otherNews2 = Object.values(this.entriesList.ubio).slice(6,18)
								})
				}
			} else if(to.path.includes('/opinion/')){
				if (localStorage.appData) {
					this.entriesList = JSON.parse(localStorage.appData)
					this.article = this.entriesList.opinion[this.$route.params.id]
					this.otherNews = Object.values(this.entriesList.opinion).slice(0,6)
					this.otherNews2 = Object.values(this.entriesList.opinion).slice(6,18)
				} else {
					axios.get(jsonDataURL)
						.then(response => {
							this.entriesList = response.data
							localStorage.setItem('appData', JSON.stringify(response.data));
							this.article = this.entriesList.opinion[this.$route.params.id]
							this.otherNews = Object.values(this.entriesList.opinion).slice(0,6)
							this.otherNews2 = Object.values(this.entriesList.opinion).slice(6,18)
								})
				}
			} else if(to.path.includes('/congress/')){
				if (localStorage.appData) {
					this.entriesList = JSON.parse(localStorage.appData)
					this.article = this.entriesList.congress[this.$route.params.id]
					this.otherNews = Object.values(this.entriesList.congress).slice(0,6)
					this.otherNews2 = Object.values(this.entriesList.congress).slice(6,18)
				} else {
					axios.get(jsonDataURL)
						.then(response => {
							this.entriesList = response.data
							localStorage.setItem('appData', JSON.stringify(response.data));
							this.article = this.entriesList.congress[this.$route.params.id]
							this.otherNews = Object.values(this.entriesList.congress).slice(0,6)
							this.otherNews2 = Object.values(this.entriesList.congress).slice(6,18)
						})
				}
			} else {
				if (localStorage.appData) {
					this.entriesList = JSON.parse(localStorage.appData)
					this.article = this.entriesList.news[this.$route.params.id]
					this.otherNews = Object.values(this.entriesList.news).slice(0,6)
					this.otherNews2 = Object.values(this.entriesList.news).slice(6,18)
				} else {
					axios.get(jsonDataURL)
						.then(response => {
							this.entriesList = response.data
							localStorage.setItem('appData', JSON.stringify(response.data));
							this.article = this.entriesList.news[this.$route.params.id]
							this.otherNews = Object.values(this.entriesList.news).slice(0,6)
							this.otherNews2 = Object.values(this.entriesList.news).slice(6,18)
						})
				}
				// this.$refs.commentsButton.style.display = 'block'
				// this.$refs.blogCommentForm.style.display = 'none'
				// this.$refs.commentsContainer.innerHTML = ''
			}
			this.$el.querySelector("#container").scrollIntoView({behavior: 'smooth'});
		}
	}
};
</script>

<style>
	@import '/assets/css/main.css';
</style>
